// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ambientes-js": () => import("./../../../src/pages/ambientes.js" /* webpackChunkName: "component---src-pages-ambientes-js" */),
  "component---src-pages-busca-js": () => import("./../../../src/pages/busca.js" /* webpackChunkName: "component---src-pages-busca-js" */),
  "component---src-pages-criar-ambiente-js": () => import("./../../../src/pages/criar-ambiente.js" /* webpackChunkName: "component---src-pages-criar-ambiente-js" */),
  "component---src-pages-editar-perfil-js": () => import("./../../../src/pages/editar-perfil.js" /* webpackChunkName: "component---src-pages-editar-perfil-js" */),
  "component---src-pages-esqueci-minha-senha-js": () => import("./../../../src/pages/esqueci-minha-senha.js" /* webpackChunkName: "component---src-pages-esqueci-minha-senha-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-meu-perfil-js": () => import("./../../../src/pages/meu-perfil.js" /* webpackChunkName: "component---src-pages-meu-perfil-js" */),
  "component---src-pages-registrar-js": () => import("./../../../src/pages/registrar.js" /* webpackChunkName: "component---src-pages-registrar-js" */),
  "component---src-pages-resetar-senha-js": () => import("./../../../src/pages/resetar-senha.js" /* webpackChunkName: "component---src-pages-resetar-senha-js" */),
  "component---src-pages-termos-de-utilizacao-js": () => import("./../../../src/pages/termos-de-utilizacao.js" /* webpackChunkName: "component---src-pages-termos-de-utilizacao-js" */),
  "component---src-templates-edit-project-index-js": () => import("./../../../src/templates/EditProject/index.js" /* webpackChunkName: "component---src-templates-edit-project-index-js" */),
  "component---src-templates-professionals-index-js": () => import("./../../../src/templates/Professionals/index.js" /* webpackChunkName: "component---src-templates-professionals-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/Project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */)
}

