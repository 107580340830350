import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background: #DEDEDE;
    overflow-x: hidden;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *, button, input {
    border: 0;
    outline: 0;

    font-family: 'Montserrat', sans-serif;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .input-text {
    font: italic bold 20px 'Times new roman';
    color: #000000;
    padding: 12px 0;
    border: 0;
    border-bottom: 1px solid #b1b1b1;
    background: transparent;
    width: 100%;

    &::placeholder {
      color: #000000;
    }
  }
  .input-textarea {
    font: italic bold 20px 'Times new roman';
    color: #000000;
    padding: 17px 20px;
    border: 1px solid #b1b1b1;
    background: transparent;
    width: 100%;
    height: 285px;

    &::placeholder {
      color: #000000;
    }
  }

  .input-select {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #b3b3b3;
    min-width: 160px;
      color: #000000;
    padding: 12px 0;
    font: italic bold 19px 'Times new roman';
    appearance: none;
    border-radius: 0;
    
    background: transparent url('/images/arrow-down.png') no-repeat center;
    background-position: 99%;
  }

  .text-center {
    text-align: center;
  }
`;

export default GlobalStyle;