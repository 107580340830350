import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [getUser, setUser] = useState({});

  const removeUser = () => localStorage.removeItem("@authUser");

  function signIn(user) {
    localStorage.setItem("@authUser", JSON.stringify(user))
    setUser(user);
    setIsLoggedIn(true);
  }

  const singOut = callback => {
    setIsLoggedIn(false);
    removeUser();
    callback();
  }

  function handleIsLoggedIn(value) {
    setUser(JSON.parse(localStorage.getItem("@authUser")));
    setIsLoggedIn(value ? true : false);
  }

  return(
    <Provider
      value={{
        isLoggedIn,
        signIn,
        singOut,
        getUser,
        handleIsLoggedIn
      }}
    >
      {children}
    </Provider>
  );
}

export { AuthProvider };