import React from "react";
import MetaTags from "react-meta-tags"

import { AuthProvider } from "./src/context/auth";

import GlobalStyles from './src/assets/globalStyles';

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <MetaTags>
      <link rel="icon" href="https://guararapes-meuambiente.s3.sa-east-1.amazonaws.com/favicon.ico" type="image/png"/>
    </MetaTags>
    {element}
    <GlobalStyles />
  </AuthProvider>
)